<template>
  <div class="materialManagement page-info-content">
    <div class="contentArea">
      <div class="left page-tree">
        <LeftTree :tree-data="leftTreeData" @getTreeData="getTreeData" />
      </div>
      <div class="right">
        <!-- 按钮 -->
        <div class="button">
          <el-button size="small" type="primary" @click="editAndAddRow()">
            添加
          </el-button>
          <el-button
            v-show="formInline.state == 0"
            size="small"
            type="success"
            @click="disableEnabled(1)"
          >
            启用
          </el-button>
          <el-button
            v-show="formInline.state == 1"
            size="small"
            type="danger"
            @click="disableEnabled(0)"
          >
            禁用
          </el-button>
        </div>
        <!-- 搜索筛选 -->
        <div class="buttonCombination">
          <span>品种详细信息</span>
        </div>
        <!-- 展示框 -->
        <el-form :model="formInline" label-width="78px" class="form-search-demo">
          <el-form-item label="品种代码:">
            <el-input v-model="formInline.code" size="small" disabled placeholder="品种代码" />
          </el-form-item>
          <el-form-item label="品种大类:">
            <el-input v-model="formInline.parentName" size="small" disabled placeholder="品种大类" />
          </el-form-item>
          <el-form-item label="品种名称:">
            <el-input v-model="formInline.goodsName" size="small" disabled placeholder="品种名称" />
          </el-form-item>
          <el-form-item label="品种状态:">
            <el-input v-model="formInline.stateName" size="small" disabled placeholder="品种状态" />
          </el-form-item>
          <el-form-item label="品种别名:">
            <el-input v-model="formInline.aliasName" size="small" disabled placeholder="品种别名" />
          </el-form-item>
        </el-form>
      </div>
    </div>
    <!-- 编辑and新增弹出层 -->
    <Dialog
      ref="dialog"
      :edit-form-data="editFormData"
      :form-item="formItem"
      dialog-width="35%"
      @getFormData="getFormData"
    />
  </div>
</template>

<script>
import Dialog from '../../components/Dialog.vue'
import LeftTree from './components/LeftTree'
import { translateDataToTree } from '../../utils/util'
import { getGoodsList, goodsAdd, goodsDelete } from '../../api/goods'
export default {
  // 注册组件
  components: {
    Dialog,
    LeftTree
  },
  data() {
    return {
      loading: false,
      pinzhong: '',
      editFormData: {},
      leftTreeData: [],
      formItem: [
        {
          type: 'cascader',
          value: 'allVarieties',
          label: '品种大类',
          rules: 'none',
          props: { label: 'goodsName', value: 'code', checkStrictly: true },
          options: []
        },
        {
          type: 'input',
          value: 'goodsName',
          label: '品种名称',
          rules: [{ required: true, message: '请输入', trigger: ['blur', 'change'] },
            { min: 1, max: 10, message: '不超过10个字符', trigger: 'blur' }]
        }, {
        }, {
          type: 'input',
          value: 'aliasName',
          label: '品种别名',
          rules: [{ required: true, message: '请输入', trigger: ['blur', 'change'] },
            { min: 1, max: 100, message: '不超过100个字符', trigger: 'blur' }]
        }, {
          type: 'radio',
          value: 'state',
          label: '状态',
          child: [{ label: '有效', value: 1 }, { label: '无效', value: 0 }]
        }
      ],
      listData: [],
      formInline: {},
      data: [],
      total: 0
    }
  },
  created() {
    this.init()
  },

  /**
   * 里面的方法只有被调用才会执行
   */
  methods: {
    // 初始化数据
    init() {
      getGoodsList(res => {
        this.data = [...res.data]
        res.data.forEach(item => {
          if (item.code.length > 4) item.disabled = true
        })
        this.leftTreeData = translateDataToTree(res.data, 'parentCode', 'code')
      })
    },
    disableEnabled(state) {
      this.$confirm('此操作将改变品种状态, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        goodsDelete({ code: this.formInline.code, id: this.formInline.id, state }, res => {
          this.formInline.state = state
          this.formInline.stateName = state === 1 ? '有效' : '无效'
          if (state === 0) this.formInline = {}
          this.init()
          this.$message.success('操作成功！')
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    // 新增的弹出窗口
    editAndAddRow() {
      // if(this.editFormData.allVarieties.length===3){
      //   this.$message.error('不能给三级品种添加子节点！')
      //   return
      // }
      this.editFormData = { allVarieties: [...this.editFormData.allVarieties || []], state: 1 }
      this.formItem[0].options = this.leftTreeData
      this.$refs.dialog.editFormVisible = true
    },
    // 点击新增或者修改的确认按钮
    getFormData(value) {
      const row = { ...value }
      row.parentCode = row.allVarieties && row.allVarieties.length > 0 ? row.allVarieties[row.allVarieties.length - 1] : ''
      goodsAdd(row, () => {
        this.init()
        this.$message.success('添加成功！')
        this.$refs.dialog.editFormVisible = false
      })
    },
    // 获取树结构的相应东西
    getTreeData(value) {
      switch (value.level) {
        case 1:
          this.editFormData.allVarieties = [value.code]
          break
        case 2:
          this.editFormData.allVarieties = [this.editFormData.allVarieties[0], value.code]
          break
        case 3:
          this.editFormData.allVarieties = [this.editFormData.allVarieties[0], value.row.parentCode, value.code]
          break
      }
      this.formInline = { ...value.row }
      this.formInline.stateName = this.formInline.state === 1 ? '有效' : '无效'
    }
  }
}
</script>

<style scoped>
.contentArea {
  display: flex;
  margin-top: 20px;
  overflow-x: hidden;
  flex: 1;
}
.contentArea .left {
  flex: 0 0 200px;
  padding-right: 12px;
  overflow-x: auto;
  margin-right: 20px;
}
.contentArea .right {
  width: calc(100% - 212px);
}
.form-search-demo {
  border: 1px solid #EBEBEB;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.form-search-demo .el-select,
.form-search-demo .el-input {
  width: 288px;
}
.button {
  padding: 0 16px;
  height: 48px;
  line-height: 48px;
  border-top: 0;
  text-align: right;
}
.buttonCombination {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 42px;
  font-size: 14px;
  border: 1px solid #eee;
  border-bottom: 0;
  padding: 0 16px;
}
</style>

